/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { isAfter } from 'date-fns';
import useGlobalStore from '@useGlobalStore';

import { SessionStorage, Locale, BrowserList } from '@enums';
import ratingFminus from '@assets/images/result/fMinus.svg';
import ratingF from '@assets/images/result/f.svg';
import ratingFplus from '@assets/images/result/fPlus.svg';
import ratingFF from '@assets/images/result/ff.svg';
import ratingFFplus from '@assets/images/result/ffPlus.svg';
import ratingFFF from '@assets/images/result/fff.svg';
import ratingFFFplus from '@assets/images/result/fffPlus.svg';

interface EnvVariables {
  readonly mode: string;
  NX_PUBLIC_HEAP_ANALYTICS: boolean;
  NX_PUBLIC_HEAP_ANALYTICS_KEY: string;
  NX_PUBLIC_STONLY_KEY: string;
  NX_PUBLIC_USE_DEALBREAK_PAGE: boolean;
  NX_PUBLIC_IS_WIDGET_DEV_MODE: boolean;
  NX_PUBLIC_BASE_URL: string;
  NX_PUBLIC_API_CONTROLLER: string;
  NX_PUBLIC_API_GATEWAY: string;
  NX_PUBLIC_WIDGET_BASE_URL: string;
  NX_PUBLIC_TRANSLATIONS_URL: string;
  NX_PUBLIC_ADVISORY_BASE_URL: string;
  NX_PUBLIC_ADVISORY_REDIRECT_TIMEOUT: string;
  NX_PUBLIC_DISPLAY_REDIRECTION_MODAL: boolean;
  NX_PUBLIC_CAPTCHA_KEY: string;
  NX_PUBLIC_TENDERING_WARNING_ENABLED: boolean;
  NX_PUBLIC_MAINTENANCE_URL: string;
  NX_PUBLIC_LANGUAGE: string;
  NX_PUBLIC_TINY_MCE_FOLDER_URL: string;
  NX_PUBLIC_PRINTABLE_COMPARISON: boolean;
  NX_PUBLIC_UNLEASH_API_FRONTEND_URL: string;
  NX_PUBLIC_UNLEASH_API_FRONTEND_TOKEN: string;
}

// TMP Remove ternary operator after we move to Next.js
export const envVariables: EnvVariables = {
  mode: process.env.NODE_ENV || '',
  NX_PUBLIC_HEAP_ANALYTICS: process.env.NX_PUBLIC_HEAP_ANALYTICS?.toLowerCase() === 'true',
  NX_PUBLIC_HEAP_ANALYTICS_KEY: process.env.NX_PUBLIC_HEAP_ANALYTICS_KEY || '',
  NX_PUBLIC_STONLY_KEY: process.env.NX_PUBLIC_STONLY_KEY || '',
  NX_PUBLIC_USE_DEALBREAK_PAGE:
    process.env.NX_PUBLIC_USE_DEALBREAK_PAGE?.toLowerCase() === 'true',
  NX_PUBLIC_IS_WIDGET_DEV_MODE:
    process.env.NX_PUBLIC_IS_WIDGET_DEV_MODE?.toLowerCase() === 'true',
  NX_PUBLIC_BASE_URL: process.env.NX_PUBLIC_BASE_URL || '',
  NX_PUBLIC_API_CONTROLLER: process.env.NX_PUBLIC_API_CONTROLLER || '',
  NX_PUBLIC_API_GATEWAY: process.env.NX_PUBLIC_API_GATEWAY || '',
  NX_PUBLIC_TRANSLATIONS_URL: process.env.NX_PUBLIC_TRANSLATIONS_URL || '',
  NX_PUBLIC_WIDGET_BASE_URL: process.env.NX_PUBLIC_WIDGET_BASE_URL || '',
  NX_PUBLIC_ADVISORY_BASE_URL: process.env.NX_PUBLIC_ADVISORY_BASE_URL || '',
  NX_PUBLIC_ADVISORY_REDIRECT_TIMEOUT: process.env.NX_PUBLIC_ADVISORY_REDIRECT_TIMEOUT || '0',
  NX_PUBLIC_DISPLAY_REDIRECTION_MODAL:
    process.env.NX_PUBLIC_DISPLAY_REDIRECTION_MODAL?.toLowerCase() === 'true',
  NX_PUBLIC_CAPTCHA_KEY: process.env.NX_PUBLIC_CAPTCHA_KEY || '',
  NX_PUBLIC_TENDERING_WARNING_ENABLED:
    process.env.NX_PUBLIC_TENDERING_WARNING_ENABLED?.toLowerCase() === 'true',
  NX_PUBLIC_MAINTENANCE_URL: process.env.NX_PUBLIC_MAINTENANCE_URL ?? '',
  NX_PUBLIC_LANGUAGE: process.env.NX_PUBLIC_LANGUAGE ?? '',
  NX_PUBLIC_TINY_MCE_FOLDER_URL: process.env.NX_PUBLIC_TINY_MCE_FOLDER_URL ?? '',
  NX_PUBLIC_PRINTABLE_COMPARISON:
    process.env.NX_PUBLIC_PRINTABLE_COMPARISON?.toLowerCase() === 'true',
  NX_PUBLIC_UNLEASH_API_FRONTEND_URL: process.env.NX_PUBLIC_UNLEASH_API_FRONTEND_URL ?? '',
  NX_PUBLIC_UNLEASH_API_FRONTEND_TOKEN: process.env.NX_PUBLIC_UNLEASH_API_FRONTEND_TOKEN ?? '',
};

const checkCurrencyTypeFormat = (element: string): boolean => {
  return /^([0-9.]+)+,[0-9]{2}$/.test(element);
};

// INFO: there are couples of any in this function, because params that come to the function could be different
const flatTheObject = (objectItem: any, keyName: string): any => {
  let flatObject = {};
  const flatTheObjectFunc = (item: any, name: string) => {
    const isArray = Array.isArray(item);
    const objectEntries = isArray ? item : Object.entries(item);
    objectEntries.forEach((element: any[], key: number) => {
      const elementToCheck = isArray ? element : element[1];
      const elementType = typeof elementToCheck;
      const objKey = isArray ? key : element[0];
      const objFullKey = name ? `${name}[${objKey}]` : `${objKey}`;
      if (elementType === 'string' || elementType === 'number') {
        const elementObject = { [objFullKey]: element[1] };
        flatObject = { ...flatObject, ...elementObject };
      } else if (elementType === 'object') {
        flatTheObjectFunc(elementToCheck, objFullKey);
      }
    });
    return flatObject;
  };
  return flatTheObjectFunc(objectItem, keyName);
};

const getNumberOfStars = (i: number): number => {
  let numberOfStars = 0;
  switch (i) {
    case 0:
      numberOfStars = 3;
      break;
    case 1:
      numberOfStars = 2;
      break;
    case 2:
      numberOfStars = 1;
      break;
    default:
      numberOfStars = 0;
  }
  return numberOfStars;
};

const setStickyPosition = (left = 'auto'): void => {
  const sticky = document.querySelector<HTMLDivElement>('.sticky');
  if (sticky) {
    sticky.style.left = left;
  }
};

/**
 * @param {string} url
 */
function removeTrailingSlashes(url: string): string {
  return url.replace(/\/+$/, '');
}

// TODO: refactor this and reduce complexity
const highlightItemsWhileScrolling = (
  blockScrollTop: number,
  list: string[],
  idPart: string,
  scrollPosition: number,
  currentIndex: number
): { activeIndex: number; currentScrollPosition: number } => {
  let activeIndex = currentIndex;
  let currentScrollPosition = scrollPosition;
  const extraTopOffset = 5;
  list.forEach((item, index) => {
    const el = document.getElementById(`${idPart}${index}`);
    const prevEl = document.getElementById(`${idPart}${index - 1}`);
    const nextEl = document.getElementById(`${idPart}${index + 1}`);
    if (el) {
      if (scrollPosition > blockScrollTop) {
        // scrolling up
        if (
          prevEl &&
          blockScrollTop <= el.offsetTop - extraTopOffset &&
          blockScrollTop > prevEl.offsetTop
        ) {
          activeIndex = index - 1;
          currentScrollPosition = el.offsetTop;
        } else if (!prevEl && blockScrollTop <= el.offsetTop - extraTopOffset) {
          activeIndex = index;
          currentScrollPosition = el.offsetTop;
        }
      } else if (scrollPosition < blockScrollTop) {
        // scrolling down
        if (
          nextEl
            ? blockScrollTop >= el.offsetTop && blockScrollTop < nextEl.offsetTop
            : blockScrollTop >= el.offsetTop
        ) {
          activeIndex = index;
          currentScrollPosition = el.offsetTop;
        }
      }
    }
  });
  return { activeIndex, currentScrollPosition };
};

// TODO: refactor this and reduce complexity
const getTheArrowPosition = (keyCode: number, cursor: number, list: any[]) => {
  let index = cursor;
  if (keyCode === 38 && cursor >= 0) {
    // up
    if (cursor === 0) {
      index = list.length;
    } else {
      index = cursor - 1;
    }
  } else if (keyCode === 40 && cursor <= list.length - 1) {
    // down
    if (cursor === list.length - 1) {
      index = -1;
    } else {
      index = cursor + 1;
    }
  }
  return index;
};

const getUniqId = () => {
  return `_${(
    new Date().getUTCMilliseconds().toString() + new Date().getTime().toString()
  ).toString()}`;
};

// TODO: refactor this URLSearchParams, and check for IE does it work
// const urlParams = new URLSearchParams(window.location.search);
// const myParam = urlParams.get('myParam');
/**
 * @return {[index: string]:string}
 */
const getParameters = () => {
  const query = window.location.search.replace(/^\?/g, '');
  const pairs = query.split('&');

  const parameters: { [index: string]: string } = {};
  for (let i = 0; i < pairs.length; i++) {
    const split = pairs[i].split('=');
    if (split.length === 2) {
      const [key, value] = split;
      parameters[key] = value;
    }
  }

  return parameters;
};

/**
 * @return {boolean}
 */
const hasLoginParameter = () => {
  const parameters = getParameters();
  return parameters.login === 'true';
};

/**
 * @return {string}
 */
const getCurrentUrlWithoutLoginParameter = () => {
  const parameters = getParameters();
  const path = window.location.pathname;

  let query = '';
  for (const key in parameters) {
    if (key !== 'login') {
      query += `${key}=${parameters[key]}`;
    }
  }

  if (query.length === 0) {
    return path;
  }

  return `${path}?${query}`;
};

const jsonParserFromSession = (sessionItem: SessionStorage) => {
  const item = sessionStorage.getItem(sessionItem);
  const parsedItem = item ? JSON.parse(item) : null;
  return parsedItem;
};

const getLocale = () => {
  return document.documentElement.lang;
};

/**
 * The function which creates URL with a query if a query that does not exist or extend current if exist
 * @param parameterKey query key in the URL you want to add/change
 * @returns
 */
const getUrlPathWithParam = (
  currentParamValue: string,
  newParam: string,
  locationSearch: string,
  locationPathname: string,
  parameterKey: string
): string => {
  let urlPath;
  if (!currentParamValue) {
    let search = locationSearch;
    if (search) search = `${locationSearch}&${parameterKey}`;
    else search = `?${parameterKey}`;
    urlPath = `${locationPathname}${search}=${newParam}`;
  } else {
    const searchParams = new URLSearchParams(locationSearch);
    searchParams.set(parameterKey, newParam);
    urlPath = `${locationPathname}?${searchParams}`;
  }
  return urlPath;
};

function orderElementsInArrBySpecificValue<T>(
  unorderedArray: T[],
  objectKey: keyof T,
  orderValues: string[]
) {
  const orderedArray: T[] = [];
  orderValues.forEach((orderValue) => {
    unorderedArray.forEach((element) => {
      if (String(element[objectKey]).includes(orderValue)) {
        orderedArray.push(element);
      }
    });
  });

  return orderedArray;
}

const checkIsOfferExpired = (offerExpiration?: string) => {
  return !!offerExpiration && isAfter(new Date(), new Date(offerExpiration));
};

const getDefaultGateway = (): string => {
  const { portalConfig } = useGlobalStore.getState();
  return portalConfig && portalConfig.subDomain
    ? envVariables.NX_PUBLIC_API_GATEWAY
    : envVariables.NX_PUBLIC_API_CONTROLLER;
};

const getFrankeAndBornbergRatingIcon = (rating: string) => {
  switch (rating) {
    case 'FFF+':
      return ratingFFFplus;
    case 'FFF':
      return ratingFFF;
    case 'FF+':
      return ratingFFplus;
    case 'FF':
      return ratingFF;
    case 'F+':
      return ratingFplus;
    case 'F':
      return ratingF;
    case 'F-':
      return ratingFminus;
    default:
      return '';
  }
};

const stringJoiner = (separator: string, ...args: string[]) =>
  args.filter((arg) => !!arg).join(separator);

const addressStringFormatter = (
  street = '',
  houseNumber = '',
  postCode = '',
  city = '',
  lineBreak?: boolean,
  locale?: string
) => {
  const parsedStreet = street?.replace(/&szlig;/g, 'ß');
  const streetAndNumber =
    locale === Locale.france
      ? stringJoiner(' ', houseNumber, parsedStreet)
      : stringJoiner(' ', parsedStreet, houseNumber);
  const postalCodeAndCity = stringJoiner(' ', postCode, city);

  if (lineBreak) {
    const responseArray = [streetAndNumber, postalCodeAndCity].filter((e) => e !== '');
    if (responseArray.length > 1) {
      return responseArray;
    }

    return null;
  } else {
    return stringJoiner(' ', streetAndNumber, postalCodeAndCity);
  }
};

const browserDetection = () => {
  const userAgent = navigator.userAgent;
  const isChromeBased = userAgent.includes(BrowserList.Chrome);
  const isFirefox = userAgent.includes(BrowserList.Firefox);
  const isEdge = userAgent.includes(BrowserList.Edge);
  const isSafari = userAgent.includes(BrowserList.Safari) && !isChromeBased;
  return { isChromeBased, isFirefox, isSafari, isEdge };
};

const helpers = {
  checkCurrencyTypeFormat,
  envVariables,
  flatTheObject,
  getNumberOfStars,
  setStickyPosition,
  removeTrailingSlashes,
  highlightItemsWhileScrolling,
  getTheArrowPosition,
  getUniqId,
  hasLoginParameter,
  getCurrentUrlWithoutLoginParameter,
  jsonParserFromSession,
  getLocale,
  getUrlPathWithParam,
  orderElementsInArrBySpecificValue,
  checkIsOfferExpired,
  getDefaultGateway,
  getFrankeAndBornbergRatingIcon,
  stringJoiner,
  addressStringFormatter,
  browserDetection,
};

export default helpers;
